export const documentContentDictionary = {
  pt_BR: 'Portuguese',
  en_GB: 'English',
  en_US: 'English',
  no: 'Norwegian',
  ja: 'Japanese',
  nl_NL: 'Dutch',
  es: 'Spanish',
  it: 'Italian',
  fi: 'Finnish',
  ru: 'Russian',
  sv: 'Swedish',
  da: 'Danish',
  fr: 'French',
  de: 'German',
  ko: 'Korean',
  th: 'Thai',
  es_MX: 'Spanish',
  zh_CN: 'Chinese',
  zh_TW: 'Chinese',
  'zh-cn': 'Chinese (Simplified)',
  'zh-tw': ' Chinese (Traditional)',
  ar: 'Arabic',
  bg: 'Bulgarian',
  cs: 'Croatian',
  el: 'Greek',
  iw: 'Hebrew',
  hu: 'Hungarian',
  in: 'Indonesian',
  pl: 'Polish',
  pt_PT: 'Portuguese',
  ro: 'Romanian',
  sk: 'Slovak',
  sl: 'Slovenian',
  tr: 'Turkish',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  sq: 'Albanian',
  af: 'Afrikaans',
  am: 'Amharic',
  hy: 'Armenian',
  eu: 'Basque',
  bs: 'Bosnian',
  bn: 'Bengali',
  my: 'Burmese',
  ca: 'Catalan',
  et: 'Estonian',
  fa: 'Farsi',
  kl: 'Greenlandic',
  gu: 'Gujarati',
  haw: 'Hawaiian',
  ht: 'Haitian Creole',
  hi: 'Hindi',
  is: 'Icelandic',
  ga: 'Irish',
  kn: 'Kannada',
  kk: 'Kazakh',
  km: 'Khmer',
  lv: 'Latvian',
  lt: 'Lithuanian',
  lb: 'Luxembourgish',
  mk: 'Macedonian',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mr: 'Marathi',
  sh_ME: 'Montenegrin',
  rm: 'Romansh',
  ka: 'Georgian',
  sm: 'Samoan',
  sw: 'Swahili',
  tl: 'Tagalog',
  ta: 'Tamil',
  mi: 'Te reo',
  te: 'Telugu',
  ur: 'Urdu',
  cy: 'Welsh',
  xh: 'Xhosa',
  zu: 'Zulu',
};

// Enable if needed

// Arabic (Algeria): ar_DZ
// Arabic (Bahrain): ar_BH
// Arabic (Egypt): ar_EG
// Arabic (Iraq): ar_IQ
// Arabic (Jordan): ar_JO
// Arabic (Kuwait): ar_KW
// Arabic (Lebanon): ar_LB
// Arabic (Libya): ar_LY
// Arabic (Morocco): ar_MA
// Arabic (Oman): ar_OM
// Arabic (Qatar): ar_QA
// Arabic (Saudi Arabia): ar_SA
// Arabic (Sudan): ar_SD
// Arabic (Syria): ar_SY
// Arabic (Tunisia): ar_TN
// Arabic (United Arab Emirates): ar_AE
// Arabic (Yemen): ar_YE

// Chinese (Hong Kong): zh_HK
// Chinese (Singapore): zh_SG
// Chinese (Malaysia): zh_MY
// Dutch (Belgium): nl_BE
// English (Australia): en_AU
// English (Belgium): en_BE
// English (Canada): en_CA
// English (Cyprus): en_CY
// English (Germany): en_DE
// English (Hong Kong): en_HK
// English (India): en_IN
// English (Ireland): en_IE
// English (Israel): en_IL
// English (Malaysia): en_MY
// English (Malta): en_MT
// English (Netherlands): en_NL
// English (New Zealand): en_NZ
// English (Philippines): en_PH
// English (Singapore): en_SG
// English (South Africa): en_ZA
// English (United Arab Emirates): en_AE

// French (Belgium): fr_BE
// French (Canada): fr_CA
// French (Luxembourg): fr_LU
// French (Morocco): fr_MA
// French (Switzerland): fr_CH
// German (Austria): de_AT
// German (Belgium): de_BE
// German (Luxembourg): de_LU
// German (Switzerland): de_CH
// Greek (Cyprus): el_CY
// Italian (Switzerland): it_CH

// Romanian (Moldova): ro_MD
// Russian (Armenia): ru_AM
// Russian (Belarus): ru_BY
// Russian (Kazakhstan): ru_KZ
// Russian (Kyrgyzstan): ru_KG
// Russian (Lithuania): ru_LT
// Russian (Moldova): ru_MD
// Russian (Poland): ru_PL
// Russian (Ukraine): ru_UA
// Serbian (Cyrillic): sr
// Serbian (Latin): sh
// Spanish (Argentina): es_AR
// Spanish (Bolivia): es_BO
// Spanish (Chile): es_CL
// Spanish (Colombia): es_CO
// Spanish (Costa Rica): es_CR
// Spanish (Dominican Republic): es_DO
// Spanish (Ecuador): es_EC
// Spanish (El Salvador): es_SV
// Spanish (Guatemala): es_GT
// Spanish (Honduras): es_HN
// Spanish (Nicaragua): es_NI
// Spanish (Panama): es_PA
// Spanish (Paraguay): es_PY
// Spanish (Peru): es_PE
// Spanish (Puerto Rico): es_PR
// Spanish (United States): es_US
// Spanish (Uruguay): es_UY
// Spanish (Venezuela): es_VE
