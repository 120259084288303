import forEach from 'lodash-es/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import set from 'lodash/set';
import * as React from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';

import { useAuth } from '../auth/auth.hooks';
import { defaultFiltersDictionary } from './filters.dictionary';
import {
  AssignmentsFilter,
  AssignmentsFilterType,
  DefaultFilters,
  Filters,
  FiltersResponse,
  FilterType,
  FilterValue,
} from './filters.types';

export const useFilterTypes = (): UseQueryResult<Filters> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    ['filters'],
    () => api.get<FiltersResponse>(`/filters`).then(({ data }) => data.payload),
    {
      // prevent filters re-fetching as they are static
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),

      onError: () =>
        UIKit.showToast('Fetching filters failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

export const useFilterAutocompletion = ({
  focused,
  selection,
  filterType,
  searchValue,
  dependentOn,
  enabledByDefault,
}: {
  focused: boolean;
  searchValue: string;
  filterType: FilterType;
  enabledByDefault?: boolean;
  selection: Partial<AssignmentsFilter>;
  dependentOn?: Partial<AssignmentsFilterType>[];
}): UseQueryResult<FilterValue[]> => {
  const { accessToken, api } = useAuth();

  const focusedWithSearchValue = focused && Boolean(searchValue);

  const dependency =
    dependentOn && !isEmpty(selection) ? pick(selection, dependentOn) : '';

  return useQuery(
    [`filter/${filterType}`, dependency, searchValue],
    () => {
      const filters: Record<string, string> = {};

      keys(selection).forEach((filter) => {
        const options: string = get(selection, filter)
          .map((option: FilterValue) => option.value)
          .toString();

        if (options) set(filters, filter, options);
      });

      return api
        .get<FiltersResponse>(`/filters/${filterType}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: searchValue
            ? merge(filters, { search: searchValue })
            : filters,
        })
        .then(({ data }) => data.payload);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(accessToken) &&
        (focusedWithSearchValue || enabledByDefault || !isEmpty(dependency)),

      onError: () =>
        UIKit.showToast('Fetching filters failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );
};

const allowedQueryParameters: Array<keyof typeof defaultFiltersDictionary> = [
  'id',
  'prt',
  'doc',
  'search',
  'subtype',
  'equipno',
  'maintype',
  'portfolio',
  'visibility',
  'installation',
  'producttype',
];

// This hook contains a collection of different filters from a query string
// and it is impossible to declare exact interface that will suit consumers
// The proposal is to use generic return value and make a check for consumer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDefaultFilters = (): {
  defaultFilters: DefaultFilters;
  isLoading: boolean;
} => {
  const { accessToken, api } = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [defaultFilters, setDefaultFilters] = React.useState({});
  const { documentNumber } = useParams<{ documentNumber?: string }>();

  const queryParameters: typeof defaultFiltersDictionary = pick(
    Object.fromEntries(new URLSearchParams(useLocation().search)),
    allowedQueryParameters
  );

  React.useEffect(() => {
    if (documentNumber) setDefaultFilters((s) => ({ ...s, documentNumber }));
  }, []);

  React.useEffect(() => {
    if (accessToken) {
      setLoading(true);

      const fetchFiltersLabels = async (
        filterType: string,
        filtersToQuery: string[]
      ): Promise<FilterValue[]> => {
        let filtersWithLabels: FilterValue[] = [];
        for (
          let filterIndex = 0;
          filterIndex < filtersToQuery.length;
          // eslint-disable-next-line no-plusplus
          filterIndex++
        ) {
          const filterToQuery = filtersToQuery[filterIndex];

          filtersWithLabels = filtersWithLabels.concat(
            // eslint-disable-next-line no-await-in-loop
            await api
              .get(
                `/filters/${filterType}/search/${encodeURIComponent(
                  filterToQuery
                )}`
              )
              .then(
                ({ data }) => {
                  if (data.payload.length) return data.payload;
                  // Searched default value
                  return [{ value: filterToQuery, label: filterToQuery }];
                },
                // Searched default value if error
                () => [{ value: filterToQuery, label: filterToQuery }]
              )
          );
        }

        return filtersWithLabels;
      };

      const fetchFilters = async (): Promise<{
        [key: string]: FilterValue[];
      }> => {
        const assignmentFilters: { [key: string]: FilterValue[] } = {};
        const filterTypes = keys(queryParameters).filter((key) =>
          [
            'prt',
            'equipno',
            'portfolio',
            'installation',
            'producttype',
          ].includes(key)
        ) as FilterType[];

        for (
          let filterTypeIndex = 0;
          filterTypeIndex < filterTypes.length;
          // eslint-disable-next-line no-plusplus
          filterTypeIndex++
        ) {
          const filterType = filterTypes[filterTypeIndex];

          // eslint-disable-next-line no-await-in-loop
          const filtersLabels = await fetchFiltersLabels(
            get(defaultFiltersDictionary, filterType, filterType),
            get(queryParameters, filterType, '').split(',')
          );

          set(
            assignmentFilters,
            get(defaultFiltersDictionary, filterType, filterType),
            filtersLabels
          );
        }

        return assignmentFilters;
      };

      const filters: { [key: string]: DefaultFilters } = {};
      forEach(keys(queryParameters), (key) => {
        const filterValue = get(queryParameters, key);
        const filterName = get(defaultFiltersDictionary, key, key);

        switch (key) {
          case 'id':
          case 'doc':
          case 'search':
            set(filters, filterName, filterValue);
            break;
          case 'subtype':
          case 'maintype':
            set(filters, `documentTypes.${filterName}`, filterValue.split(','));
            break;
          case 'visibility':
            set(filters, filterName, filterValue.split(','));
            break;
          default:
            break;
        }
      });

      fetchFilters().then(
        (assignmentFilters) => {
          if (Object.keys(assignmentFilters).length > 0) {
            setDefaultFilters({
              ...filters,
              assignmentFilters,
            });
          } else {
            setDefaultFilters(filters);
          }
          setLoading(false);
        },
        () => {
          setDefaultFilters(filters);
          setLoading(false);

          UIKit.showToast('Fetching assignment filters failed', {
            title: 'Error!',
            variant: ToastVariant.Warning,
            position: ToastPosition.BottomRight,
          });
        }
      );
    }
  }, [accessToken]);

  return { defaultFilters, isLoading };
};
