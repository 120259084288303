import * as React from 'react';
import * as WUI from '@wartsila/ui-kit';

import get from 'lodash/get';

import { findKey, forEach, isEqual, values } from 'lodash';
import { filterDictionary } from '../filters/filters.dictionary';
import { useMissingDocRequest } from './docrequest.hooks';

const modalTitle = 'Request for missing document';
const userInputTitleHelperText = 'Add a title (mandatory)';
const userInputBodyHelperText = 'Please explain the request (optional)';

interface MissingDocsRequestProps {
  link?: boolean;
  filters?: { [key: string]: unknown };
  queryParams?: string;
}

export const MissingDocRequest = ({
  link = false,
  filters = {},
  queryParams = '',
}: MissingDocsRequestProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [comment, setComment] = React.useState('');

  const { mutate } = useMissingDocRequest();

  const formatFilters = (): string => {
    const formatedFilters: string[] = [];
    const formatedFiltersSubtypes: string[] = [];

    if (queryParams) {
      formatedFilters.push(`Query Parameters = ${queryParams}`);
    } else {
      forEach(filters, (filter, filterName) => {
        if (!filter) return;

        if (typeof filter === 'string') {
          formatedFilters.push(
            `${get(filterDictionary, filterName, filterName)} = ${filter}`
          );
          return;
        }

        if (typeof filter === 'boolean') {
          formatedFilters.push(get(filterDictionary, filterName, filterName));
          return;
        }

        if (typeof filter === 'object' || filter !== null) {
          // Exception for Spare Parts Catalogue (SPC) - this is a maintype without subtypes and should be displayed differently
          const exceptionMainTypeSPC = 'SPC';
          if (Object.keys(filter).includes(exceptionMainTypeSPC)) {
            formatedFilters.push(
              `Maintypes = ${get(filterDictionary, exceptionMainTypeSPC)}`
            );
          }
          forEach(values(filter), (subTypes: string[] | never) => {
            if (subTypes?.length) {
              const formattedSubTypes: string[] = [];
              forEach(
                subTypes,
                (subType) => subType && formattedSubTypes.push(subType)
              );

              const usedFilter =
                findKey(filter, (value) => isEqual(value, subTypes)) || '';
              forEach(formattedSubTypes, (subType) =>
                formatedFiltersSubtypes.push(
                  `${subType} (${get(
                    filterDictionary,
                    usedFilter,
                    usedFilter
                  )})`
                )
              );
            }
          });
        }
      });
    }
    if (formatedFiltersSubtypes.length) {
      formatedFilters.push(`Subtypes = ${formatedFiltersSubtypes.join(', ')}`);
    }
    return formatedFilters.join('\n');
  };

  return (
    <span>
      {link ? (
        <button
          type="button"
          className="footer__docrequest"
          onClick={() => setIsOpen(true)}>
          {modalTitle}
        </button>
      ) : (
        <WUI.Button
          variant={WUI.ButtonVariant.Warning}
          onClick={() => setIsOpen(true)}>
          {modalTitle}
        </WUI.Button>
      )}
      <WUI.Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <WUI.Modal.Header title={modalTitle} onClose={() => setIsOpen(false)} />
        <WUI.Modal.Content>
          <WUI.FormRow label={userInputTitleHelperText}>
            <WUI.InputField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </WUI.FormRow>
          <WUI.FormRow label={userInputBodyHelperText}>
            <WUI.TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </WUI.FormRow>
          <WUI.Button
            onClick={() => {
              mutate({ title, comment, filters: formatFilters() });
              setTitle('');
              setComment('');
              setIsOpen(false);
            }}>
            Send
          </WUI.Button>
        </WUI.Modal.Content>
      </WUI.Modal>
    </span>
  );
};
